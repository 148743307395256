function testJson() {
  return [
    {
      id: 51105,
      title: "NieR:Automata Ver1.1a",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1399/128318.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1399/128318l.jpg",
      },
      genres: ["Action", "Fantasy", "Sci-Fi"],
      media_type: "tv",
      studios: ["A-1 Pictures"],
      mean: 7.44,
      num_list_users: 223484,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: "watching",
      genreaveragescore: 0.9951916033,
      clusterscore: 0.6616008665,
      studioaveragescore: 1,
      popularityscorer: 0.5881248385,
      recommend_score: 0.8112293271,
    },
    {
      id: 49387,
      title: "Vinland Saga Season 2",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1170/124312.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1170/124312l.jpg",
      },
      genres: ["Action", "Adventure", "Drama", "Gore", "Historical", "Seinen"],
      media_type: "tv",
      studios: ["MAPPA"],
      mean: 8.68,
      num_list_users: 379658,
      rating: "r",
      start_season: "2023/winter",
      related_anime: [37521],
      status: "watching",
      genreaveragescore: 0.7622160088,
      clusterscore: 0.6179730235,
      studioaveragescore: 0.8036898878,
      popularityscorer: 1,
      recommend_score: 0.79596973,
    },
    {
      id: 49612,
      title: "Ningen Fushin no Boukensha-tachi ga Sekai wo Sukuu you desu",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1446/131578.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1446/131578l.jpg",
      },
      genres: ["Action", "Adventure", "Fantasy"],
      media_type: "tv",
      studios: ["Geek Toys"],
      mean: 6.33,
      num_list_users: 127746,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 1,
      clusterscore: 1,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.3356365612,
      recommend_score: 0.5963378389,
    },
    {
      id: 48441,
      title: "The Legend of Heroes: Sen no Kiseki - Northern War",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1894/133297.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1894/133297l.jpg",
      },
      genres: ["Action", "Fantasy", "Military"],
      media_type: "tv",
      studios: ["Tatsunoko Production"],
      mean: 5.68,
      num_list_users: 30655,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.8561132603,
      clusterscore: 0.6738693134,
      studioaveragescore: 0.6125115259,
      popularityscorer: 0.0795800389,
      recommend_score: 0.5555185346,
    },
    {
      id: 53179,
      title: "Ars no Kyojuu",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1866/133949.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1866/133949l.jpg",
      },
      genres: ["Action", "Adventure", "Fantasy"],
      media_type: "tv",
      studios: ["Asahi Production"],
      mean: 6.13,
      num_list_users: 37768,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 1,
      clusterscore: 1,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.0983390387,
      recommend_score: 0.5370134583,
    },
    {
      id: 53446,
      title: "Tondemo Skill de Isekai Hourou Meshi",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1661/131889.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1661/131889l.jpg",
      },
      genres: ["Adventure", "Comedy", "Fantasy", "Gourmet", "Isekai"],
      media_type: "tv",
      studios: ["MAPPA"],
      mean: 7.71,
      num_list_users: 144975,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.4728352823,
      clusterscore: 0.4794149327,
      studioaveragescore: 0.8036898878,
      popularityscorer: 0.3810743239,
      recommend_score: 0.5342536067,
    },
    {
      id: 52093,
      title: "Trigun Stampede",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1426/129194.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1426/129194l.jpg",
      },
      genres: ["Action", "Adult Cast", "Adventure", "Sci-Fi", "Shounen"],
      media_type: "tv",
      studios: ["Orange"],
      mean: 7.84,
      num_list_users: 124498,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.8661335493,
      clusterscore: 0.6706580148,
      studioaveragescore: 0,
      popularityscorer: 0.3270706634,
      recommend_score: 0.4659655569,
    },
    {
      id: 53411,
      title: "Buddy Daddies",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1553/133767.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1553/133767l.jpg",
      },
      genres: ["Action", "Childcare", "Comedy", "Organized Crime"],
      media_type: "tv",
      studios: ["P.A. Works"],
      mean: 8.18,
      num_list_users: 184505,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.5335825877,
      clusterscore: 0.3588073583,
      studioaveragescore: 0.4805763459,
      popularityscorer: 0.4853261529,
      recommend_score: 0.4645731112,
    },
    {
      id: 51252,
      title: "Spy Kyoushitsu",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1491/132864.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1491/132864l.jpg",
      },
      genres: ["Action", "Fantasy", "Mystery"],
      media_type: "tv",
      studios: ["feel."],
      mean: 6.35,
      num_list_users: 119648,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: "dropped",
      genreaveragescore: 0.8725302462,
      clusterscore: 0.6474424157,
      studioaveragescore: 0,
      popularityscorer: 0.3142798369,
      recommend_score: 0.4585631247,
    },
    {
      id: 51711,
      title: "Hyouken no Majutsushi ga Sekai wo Suberu",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1049/131580.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1049/131580l.jpg",
      },
      genres: ["Action", "Fantasy", "School"],
      media_type: "tv",
      studios: ["Cloud Hearts"],
      mean: 6.35,
      num_list_users: 115192,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.8477862973,
      clusterscore: 0.6171028781,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.3025281003,
      recommend_score: 0.4542830175,
    },
    {
      id: 50932,
      title: "Saikyou Onmyouji no Isekai Tenseiki",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1547/125900.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1547/125900l.jpg",
      },
      genres: ["Action", "Adventure", "Fantasy", "Isekai", "Reincarnation"],
      media_type: "tv",
      studios: ["Studio Blanc."],
      mean: 7.21,
      num_list_users: 139315,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.6200643532,
      clusterscore: 0.7517714391,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.3661472976,
      recommend_score: 0.4469244711,
    },
    {
      id: 50481,
      title: "Eiyuu-ou, Bu wo Kiwameru Tame Tenseisu: Soshite, Sekai Saikyou no Minarai Kishi♀",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1044/129594.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1044/129594l.jpg",
      },
      genres: ["Action", "Fantasy", "Reincarnation"],
      media_type: "tv",
      studios: ["Studio Comet"],
      mean: 6.58,
      num_list_users: 110275,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.7006949712,
      clusterscore: 0.6180313011,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.2895605758,
      recommend_score: 0.4145004106,
    },
    {
      id: 52305,
      title: "Tomo-chan wa Onnanoko!",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1444/131828.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1444/131828l.jpg",
      },
      genres: ["Comedy", "Romance", "School"],
      media_type: "tv",
      studios: ["Lay-duce"],
      mean: 7.89,
      num_list_users: 278622,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.5276057649,
      clusterscore: 0.3237883745,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.7335393931,
      recommend_score: 0.4086620817,
    },
    {
      id: 52446,
      title: "Kaiko sareta Ankoku Heishi (30-dai) no Slow na Second Life",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1224/132328.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1224/132328l.jpg",
      },
      genres: ["Fantasy"],
      media_type: "tv",
      studios: ["Encourage Films"],
      mean: 7.06,
      num_list_users: 102032,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.8857967932,
      clusterscore: 0.3319996463,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.2678214453,
      recommend_score: 0.3838331698,
    },
    {
      id: 52274,
      title: "Nokemono-tachi no Yoru",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1087/129002.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1087/129002l.jpg",
      },
      genres: ["Action", "Anthropomorphic", "Fantasy", "Historical", "Shounen"],
      media_type: "tv",
      studios: ["Ashi Productions"],
      mean: 6.57,
      num_list_users: 32294,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.6886378272,
      clusterscore: 0.6909305933,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.0839025471,
      recommend_score: 0.3782964405,
    },
    {
      id: 50854,
      title: "Benriya Saitou-san, Isekai ni Iku",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1805/132335.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1805/132335l.jpg",
      },
      genres: ["Adult Cast", "Adventure", "Comedy", "Fantasy", "Isekai"],
      media_type: "tv",
      studios: ["C2C"],
      mean: 7.52,
      num_list_users: 114726,
      rating: "r",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.6164687538,
      clusterscore: 0.5129818455,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.301299126,
      recommend_score: 0.3701161299,
    },
    {
      id: 50739,
      title: "Otonari no Tenshi-sama ni Itsunomanika Dame Ningen ni Sareteita Ken",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1240/133638.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1240/133638l.jpg",
      },
      genres: ["Comedy", "Romance", "School"],
      media_type: "tv",
      studios: ["Project No.9"],
      mean: 7.85,
      num_list_users: 215337,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.5276057649,
      clusterscore: 0.3237883745,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.5666388873,
      recommend_score: 0.3669369553,
    },
    {
      id: 50864,
      title: "Ooyukiumi no Kaina",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1064/131833.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1064/131833l.jpg",
      },
      genres: ["Fantasy", "Survival"],
      media_type: "tv",
      studios: ["Polygon Pictures"],
      mean: 6.69,
      num_list_users: 41123,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: "watching",
      genreaveragescore: 0.5964665736,
      clusterscore: 0.280229011,
      studioaveragescore: 0.4474331496,
      popularityscorer: 0.1071871258,
      recommend_score: 0.357828965,
    },
    {
      id: 49154,
      title: "High Card",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1803/131345.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1803/131345l.jpg",
      },
      genres: ["Action", "Adult Cast", "Fantasy", "Organized Crime", "Super Power"],
      media_type: "tv",
      studios: ["Studio Hibari"],
      mean: 7.14,
      num_list_users: 75096,
      rating: "r",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.61812737,
      clusterscore: 0.5149853904,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.1967835687,
      recommend_score: 0.3449027809,
    },
    {
      id: 43760,
      title: "Hikari no Ou",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1787/131675.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1787/131675l.jpg",
      },
      genres: ["Fantasy"],
      media_type: "tv",
      studios: ["Signal.MD"],
      mean: 6.42,
      num_list_users: 36917,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.8857967932,
      clusterscore: 0.3319996463,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.0960947101,
      recommend_score: 0.340901486,
    },
    {
      id: 51815,
      title: "Kubo-san wa Mob wo Yurusanai",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1818/132330.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1818/132330l.jpg",
      },
      genres: ["Comedy", "Romance", "School", "Seinen"],
      media_type: "tv",
      studios: ["PINE JAM"],
      mean: 7.57,
      num_list_users: 136663,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.5376561529,
      clusterscore: 0.3496864443,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.3591532209,
      recommend_score: 0.3240526531,
    },
    {
      id: 53424,
      title: "Flaglia: Natsuyasumi no Monogatari",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1065/129807.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1065/129807l.jpg",
      },
      genres: ["Fantasy"],
      media_type: "tv",
      studios: ["Gaina"],
      mean: 5.54,
      num_list_users: 4246,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.8857967932,
      clusterscore: 0.3319996463,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.0099320108,
      recommend_score: 0.3193608112,
    },
    {
      id: 52173,
      title: "Koori Zokusei Danshi to Cool na Douryou Joshi",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1927/132758.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1927/132758l.jpg",
      },
      genres: ["Adult Cast", "Comedy", "Fantasy", "Mythology", "Romance", "Workplace"],
      media_type: "tv",
      studios: ["Zero-G", "Liber"],
      mean: 7.36,
      num_list_users: 152723,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.4505848301,
      clusterscore: 0.2465260656,
      studioaveragescore: 0.1114924207,
      popularityscorer: 0.4015079989,
      recommend_score: 0.3025278288,
    },
    {
      id: 50384,
      title: "Mononogatari",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1173/131830.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1173/131830l.jpg",
      },
      genres: ["Action", "Adult Cast", "Mythology", "Seinen", "Supernatural"],
      media_type: "tv",
      studios: ["Bandai Namco Pictures"],
      mean: 7.17,
      num_list_users: 40764,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.6202252638,
      clusterscore: 0.3912247949,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.1062403409,
      recommend_score: 0.2918512985,
    },
    {
      id: 51462,
      title: "Isekai Nonbiri Nouka",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1983/132329.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1983/132329l.jpg",
      },
      genres: ["Fantasy", "Isekai", "Slice of Life"],
      media_type: "tv",
      studios: ["Zero-G"],
      mean: 7.6,
      num_list_users: 133045,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.3622583361,
      clusterscore: 0.2670441986,
      studioaveragescore: 0.173270047,
      popularityscorer: 0.3496115281,
      recommend_score: 0.2880460274,
    },
    {
      id: 53213,
      title: "Revenger",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1884/131748.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1884/131748l.jpg",
      },
      genres: ["Action", "Adult Cast", "Drama", "Historical", "Mystery", "Organized Crime", "Samurai"],
      media_type: "tv",
      studios: ["Ajia-do"],
      mean: 6.69,
      num_list_users: 62855,
      rating: "r",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.5437677656,
      clusterscore: 0.3509510075,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.1645005776,
      recommend_score: 0.2772335363,
    },
    {
      id: 49980,
      title: "Sugar Apple Fairy Tale",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1897/131300.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1897/131300l.jpg",
      },
      genres: ["Adventure", "Fantasy", "Romance", "Shoujo", "Visual Arts"],
      media_type: "tv",
      studios: ["J.C.Staff"],
      mean: 7.32,
      num_list_users: 66075,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.4004633434,
      clusterscore: 0.3944602084,
      studioaveragescore: 0.0994295888,
      popularityscorer: 0.1729926314,
      recommend_score: 0.266836443,
    },
    {
      id: 51940,
      title: "Boku to Roboko",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1986/134747.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1986/134747l.jpg",
      },
      genres: ["Comedy", "Gag Humor", "Sci-Fi", "Shounen"],
      media_type: "tv",
      studios: ["Gallop"],
      mean: 6.33,
      num_list_users: 5809,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.6195876432,
      clusterscore: 0.3576663264,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.0140540854,
      recommend_score: 0.2602557123,
    },
    {
      id: 50606,
      title: "Ayakashi Triangle",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1078/131921.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1078/131921l.jpg",
      },
      genres: ["Action", "Comedy", "Ecchi", "Magical Sex Shift", "Mythology", "Romance", "Shounen", "Supernatural"],
      media_type: "tv",
      studios: ["Connect"],
      mean: 6.37,
      num_list_users: 50971,
      rating: "r+",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.4931661648,
      clusterscore: 0.3640467804,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.1331590968,
      recommend_score: 0.2600217091,
    },
    {
      id: 52461,
      title: "Rougo ni Sonaete Isekai de 8-manmai no Kinka wo Tamemasu",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1890/131473.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1890/131473l.jpg",
      },
      genres: ["Fantasy", "Isekai"],
      media_type: "tv",
      studios: ["Felix Film"],
      mean: 6.93,
      num_list_users: 64165,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.5137675642,
      clusterscore: 0.3000685265,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.1679554194,
      recommend_score: 0.2578765761,
    },
    {
      id: 50652,
      title: "Tsundere Akuyaku Reijou Liselotte to Jikkyou no Endou-kun to Kaisetsu no Kobayashi-san",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1997/131327.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1997/131327l.jpg",
      },
      genres: ["Comedy", "Fantasy", "Romance", "Video Game"],
      media_type: "tv",
      studios: ["Tezuka Productions"],
      mean: 7.23,
      num_list_users: 69507,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.5124401181,
      clusterscore: 0.2835699271,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.1820437895,
      recommend_score: 0.2569421573,
    },
    {
      id: 51265,
      title: "Inu ni Nattara Suki na Hito ni Hirowareta.",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1832/132333.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1832/132333l.jpg",
      },
      genres: ["Comedy", "Ecchi"],
      media_type: "tv",
      studios: ["Quad"],
      mean: 5.16,
      num_list_users: 92631,
      rating: "r+",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.3855683495,
      clusterscore: 0.2812854738,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.2430283403,
      recommend_score: 0.2398992395,
    },
    {
      id: 51678,
      title: "Oniichan wa Oshimai!",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1058/131632.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1058/131632l.jpg",
      },
      genres: ["CGDCT", "Comedy", "Magical Sex Shift", "School", "Shounen"],
      media_type: "tv",
      studios: ["Studio Bind"],
      mean: 7.74,
      num_list_users: 106673,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.307244806,
      clusterscore: 0.2581977479,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.2800610795,
      recommend_score: 0.2238046069,
    },
    {
      id: 52736,
      title: "Tensei Oujo to Tensai Reijou no Mahou Kakumei",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1053/129004.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1053/129004l.jpg",
      },
      genres: ["Fantasy", "Girls Love", "Isekai", "Reincarnation"],
      media_type: "tv",
      studios: ["Diomedéa"],
      mean: 7.55,
      num_list_users: 143337,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.1953408454,
      clusterscore: 0.2165154797,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0.3767544531,
      recommend_score: 0.2095813932,
    },
    {
      id: 49376,
      title: "Mou Ippon!",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1039/131684.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1039/131684l.jpg",
      },
      genres: ["Combat Sports", "School", "Shounen", "Sports"],
      media_type: "tv",
      studios: ["Bakken Record"],
      mean: 7.17,
      num_list_users: 18310,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.4282680726,
      clusterscore: 0.3591431558,
      studioaveragescore: 0,
      popularityscorer: 0.0470227703,
      recommend_score: 0.2086084997,
    },
    {
      id: 50284,
      title: "Technoroid: Overmind",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1208/132388.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1208/132388l.jpg",
      },
      genres: ["Idols (Male)", "Music", "Mystery", "Sci-Fi"],
      media_type: "tv",
      studios: ["Doga Kobo"],
      mean: 6.48,
      num_list_users: 12559,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.3142306758,
      clusterscore: 0.204113737,
      studioaveragescore: 0.2435773835,
      popularityscorer: 0.0318557511,
      recommend_score: 0.1984443868,
    },
    {
      id: 53132,
      title: "UniteUp!",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1308/130458.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1308/130458l.jpg",
      },
      genres: ["Idols (Male)", "Music", "Slice of Life"],
      media_type: "tv",
      studios: ["CloverWorks"],
      mean: 7,
      num_list_users: 7549,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0,
      clusterscore: 0.0113715542,
      studioaveragescore: 0.7591645178,
      popularityscorer: 0.0186429592,
      recommend_score: 0.1972947578,
    },
    {
      id: 53655,
      title: "Yomawari Neko",
      main_picture: {
        medium: "https://api-cdn.myanimelist.net/images/anime/1085/131383.jpg",
        large: "https://api-cdn.myanimelist.net/images/anime/1085/131383l.jpg",
      },
      genres: ["Anthropomorphic", "Slice of Life"],
      media_type: "tv",
      studios: ["Shogakukan Music & Digital Entertainment"],
      mean: null,
      num_list_users: 480,
      rating: "pg_13",
      start_season: "2023/winter",
      related_anime: [],
      status: null,
      genreaveragescore: 0.0097408066,
      clusterscore: 0,
      studioaveragescore: 0.0497147944,
      popularityscorer: 0,
      recommend_score: 0.0148639002,
    },
  ];
}

export default testJson;
